import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
// eslint-disable-next-line
import { encrypt, decrypt, flatten, cleanUp, jsonify } from '@/utils/nirtara'

Vue.use(Vuex)

const axios = Axios.create({
   baseURL: (location.hostname === 'localhost' || location.hostname.match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/))
      ? `${location.protocol}//${location.hostname}:${location.protocol === 'https:' ? process.env.VUE_APP_HTTPS_PORT : process.env.VUE_APP_HTTP_PORT}` : '/',
   headers: { 'Content-type': 'application/json' },
})

export default new Vuex.Store({
   state: {
      process: '',
      token: '',
      zone: '',
      global: { Logo: '', Head: '', Hero: '', code: '', title: '', wisdom: '', description: '', copyright: '' },
      ipV4: '',
      browser: '',
      message: { Message: [{ Error: [], note: '' }], kind: '' },
      user: JSON.parse(decrypt(localStorage.getItem(process.env.VUE_APP_POST.slice(1)) || 'e30')),
      receptionist: {}, // JSON.parse(decrypt(localStorage.getItem(process.env.VUE_APP_POST.slice(1).replace('web', 'bin')) || 'e30')),
      managements: [],
   },
   getters: {
      isLoggedIn: state => !!state.user.id,
      isAdminSPA: state => state.user.code === 'SPA',
      managements: state => state.managements,
   },
   mutations: {
      setToken(state, token) {
         state.token = token
      },
      setZone(state, zone) {
         state.zone = zone
      },
      setGlobal(state, payload) {
         Object.assign(state.global, payload)
      },
      'x-forwarded-for'(state, ipV4) {
         state.ipV4 = ipV4
      },
      setBrowser(state, browser) {
         state.browser = browser
      },
      notification(state, payload) {
         state.message = payload.Message ? payload : { Message: [{ [payload.kind || 'Normal']: [], note: payload.note }], kind: payload.kind || 'Normal', timeout: payload.timeout || 4000 }
      },
      clearMessage(state) {
         state.message = { Message: [{ Error: [], note: '' }], kind: '' }
      },
      authPerson(state, payload) {
         if (payload && payload.kind) {
            payload.kind === 'Member' && (state.user = payload)
            payload.kind === 'Receptionist' && (state.receptionist = payload)
         } else {
            state.user = {}
            state.receptionist = {}
         }
      },
      managements(state, payload) {
         state.managements = payload
      },
   },
   actions: {
      async settings({ commit, state }) {
         const part = process.env.VUE_APP_POST.slice(1, 6) // nvm50
         var token = state.token
         if (!token) {
            token = localStorage.getItem(part)
            if (token) {
               token = decrypt(token)
               commit('setToken', token)
            }
         }
         if (!token) {
            const response = await axios.post(process.env.VUE_APP_POST.slice(0, 6) + 'api', { Token: [], email: process.env.VUE_APP_USERNAME }, {})
            token = response.data.Token[0]
            commit('setToken', token)
            localStorage.setItem(part, encrypt(token))
         }
         return new Promise((resolve) => {
            axios.post(process.env.VUE_APP_POST,
               { select: { site: process.env.VUE_APP_SITE, kind: 'Management' } },
               { headers: { authorization: `Bearer ${token}`, command: 'configOption' } },
            ).catch(() => {
               localStorage.removeItem(part)
               commit('notification', { note: 'Server credentials have changed, please try again', kind: 'Alert' })
            }).then(response => {
               // console.log('settings ( response.data )', jsonify(response.data))
               commit('managements', [{ name: 'All Management', zone: '*' }, ...response.data.map(j => ({ name: j.name, zone: j.zone }))])
               var management = response.data.find(j => location.hostname === j.domain)
               // DEVELOPMENT HACK -------- -------- -------- -------- -------- -------- -------- --------
               // process.env.NODE_ENV === 'development' && (management = response.data.find(j => 'visitor.cibisnine.com' === j.domain))
               process.env.NODE_ENV === 'development' && (management = response.data.find(j => 'vms.south-quarter.id' === j.domain))
               // DEVELOPMENT HACK -------- -------- -------- -------- -------- -------- -------- --------
               !management && (management = response.data.find(j => j.domain === process.env.VUE_APP_SITE) || response.data.find(j => j.site === process.env.VUE_APP_SITE))
               commit('setZone', management.zone)
               resolve(state.zone)
               const global = Object.assign(...Object.keys(management).filter(s => ['Logo', 'Head', 'Hero', 'name', 'code', 'title', 'wisdom', 'description'].includes(s)).map(k => ({ [k]: management[k] })))
               !global.code && (global.code = process.env.VUE_APP_CODE)
               !global.title && (global.title = process.env.VUE_APP_TITLE)
               !global.wisdom && (global.wisdom = process.env.VUE_APP_WISDOM)
               !global.description && (global.description = process.env.VUE_APP_DESCRIPTION)
               global.copyright = (global.name || 'NIRTARA').replace(/[\W_]+/g, ' ').split(' ')[0].toUpperCase()
               commit('setGlobal', global)
            })
         })
      },
      signin({ commit, state }, json) {
         state.process = 'signinPerson'
         return new Promise((resolve) => {
            var data = { select: { site: process.env.VUE_APP_SITE, kind: json.kind, object: { email: json.credential, password: json.password, status: 'Active' }, zone: state.zone } }
            var config = { headers: { authorization: `Bearer ${state.token}`, command: 'signinPerson' } }
            axios.post(process.env.VUE_APP_POST, data, config).then(response => {
               state.process = ''
               // console.log('signin ( response.data )', jsonify(response.data))
               if (response.data.Message) resolve(response.data)
               else if (response.data.Person) {
                  const result = flatten(response.data)
                  if (result.zone === '*' || state.zone === result.zone) {
                     commit('authPerson', result)
                     localStorage.setItem(process.env.VUE_APP_POST.slice(1), encrypt(JSON.stringify(result)))
                     // resolve({ Route: [], name: 'Dashboard' })
                     const route = { Route: [], name: 'Profile', params: { kind: '' } }
                     switch (result.code) {
                        case 'SPA': route.name = 'Settings'; route.params.kind = 'Roles'; break
                        case 'STA': route.name = 'Persons'; route.params.kind = 'Member'; break
                     }
                     resolve(route)
                  } else {
                     localStorage.removeItem(process.env.VUE_APP_POST.slice(1))
                     resolve({ Message: [{ Error: [], note: 'Failed, signin prohibited.' }], kind: 'Error' })
                  }
               } else {
                  localStorage.removeItem(process.env.VUE_APP_POST.slice(1))
                  resolve({ Message: [{ Error: [], note: 'Invalid, signin failed.' }], kind: 'Error' })
               }
            })
         })
      },
      login({ commit, state }, json) {
         if (!json) return new Promise((resolve) => {
            localStorage.removeItem(process.env.VUE_APP_POST.slice(1).replace('web', 'bin'))
            commit('authPerson', {})
            resolve()
         })
         state.process = 'loginPerson'
         return new Promise((resolve) => {
            var data = { select: { site: process.env.VUE_APP_SITE, kind: json.kind, object: { email: json.credential, password: json.password, status: 'Active' }, zone: state.zone } }
            var config = { headers: { authorization: `Bearer ${state.token}`, command: 'loginPerson' } }
            axios.post(process.env.VUE_APP_POST, data, config).then(async response => {
               state.process = ''
               // console.log('login ( response.data )', jsonify(response.data))
               if (response.data.Message) resolve(response.data)
               else if (response.data.id) {

                  process.env.NODE_ENV === 'development' && commit('setZone', 'SQO')
                  // -------- CUSTOM FOR SOUTH-QUARTER -------- //
                  if (!response.data.building && state.zone === 'SQO') {
                     // console.log('login ( !response.data.building )', jsonify(response.data))
                     try {
                        // const setting = await axios.post('https://localhost:1883/nvm50red', { building: true, numbers: true })
                        // const setting = await axios.post('http://localhost:1880/nvm50red', { building: true, numbers: true })
                        const url = process.env.NODE_ENV === 'development' ? 'https://localhost:1883/nvm50red' : 'http://localhost:1880/nvm50red'
                        const setting = await axios.post(url, { building: true, numbers: true })
                        // console.log('login ( setting )', setting?.data && jsonify(setting.data))
                        Object.assign(response.data, setting.data)
                        // console.log('login ( response.data.building )', jsonify(response.data))
                     } catch (err) {
                        // throw new Error('Unable to get a token.')
                        console.log('login ( err )', err.message || jsonify(err))
                        resolve({ Message: [{ Error: [], note: 'Client NOT configured.' }], kind: 'Error' })
                        localStorage.removeItem(process.env.VUE_APP_POST.slice(1).replace('web', 'bin'))
                        return
                     }
                  }
                  // -------- CUSTOM FOR SOUTH-QUARTER -------- //

                  commit('authPerson', response.data)
                  localStorage.setItem(process.env.VUE_APP_POST.slice(1).replace('web', 'bin'), encrypt(JSON.stringify(response.data)))
                  resolve({ Message: [{ note: 'Logged in successfully' }], kind: 'Normal' })
               } else {
                  localStorage.removeItem(process.env.VUE_APP_POST.slice(1).replace('web', 'bin'))
                  resolve({ Message: [{ Error: [], note: 'Invalid, login failed.' }], kind: 'Error' })
               }
            })
         })
      },
      logout({ commit }, json) {
         return new Promise((resolve) => {
            var key = process.env.VUE_APP_POST.slice(1)
            json.kind === 'Receptionist' && (key = key.replace('web', 'bin'))
            localStorage.removeItem(key)
            commit('authPerson', {})
            resolve()
         })
      },
      profile({ commit, state }, json) {
         // console.log('profile ( json )', jsonify(json))
         state.process = 'profilePerson'
         var data = { select: json }
         var config = { headers: { authorization: `Bearer ${state.token}`, command: 'profilePerson' } }
         axios.post(process.env.VUE_APP_POST, data, config)
            .then(response => {
               state.process = ''
               // console.log('profile ( response.data )', jsonify(response.data))
               if (response.data.Person) {
                  const result = flatten(response.data)
                  // console.log('profile ( result )', jsonify(result))
                  commit('authPerson', result)
                  localStorage.setItem(process.env.VUE_APP_POST.slice(1), encrypt(JSON.stringify(result)))
               }
            })
      },
      assistance({ commit, state }, json) {
         // console.log('assistance ( json )', jsonify(json))
         state.process = 'settingPerson'
         var data = {
            update: {
               // id: ,
               site: process.env.VUE_APP_SITE,
               kind: 'Setting',
               zone: state.zone,
               // identity: '*',
               array: [],
               object: json,
            }
         }
         var config = { headers: { authorization: `Bearer ${state.token}`, command: 'settingPerson' } }
         axios.post(process.env.VUE_APP_POST, data, config)
            .then(response => {
               state.process = ''
               response.data.Message && commit('notification', response.data)
               if (response.data.Option) {
                  const result = flatten(response.data)
                  const keys = ['face', 'qrcode', 'scanner', 'reader', 'camera', 'photo', 'image', 'legacy', 'field1', 'field2', 'field3', 'field4', 'custom']
                  keys.forEach(key => state.user[key] = result[key])
                  localStorage.setItem(process.env.VUE_APP_POST.slice(1), encrypt(JSON.stringify(state.user)))
               }
            })
      },
      mongoose({ state }, json) {
         json.headers && (state.process = json.headers.command)
         // console.log('mongoose ( json )', jsonify(json))

         var select, search, update, project, options, report
         if (json.select) {
            select = json.select
            !select.site && (select.site = process.env.VUE_APP_SITE)
            !select.zone && (select.zone = state.zone)
            select.zone === '*' && delete select.zone

            project = json.project ?? {}

            options = json.options ?? {}
            !options.sort && (options.sort = { updatedAt: -1, _id: -1 })
            !options.limit && (options.limit = process.env.VUE_APP_TABLE_LIMIT)
         }
         if (json.search) {
            search = json.search
            !search.site && (search.site = process.env.VUE_APP_SITE)
            !search.zone && (search.zone = state.zone)
            search.zone === '*' && delete search.zone

            project = json.project ?? {}

            options = json.options ?? {}
            !options.sort && (options.sort = { updatedAt: -1, _id: -1 })
            !options.limit && (options.limit = process.env.VUE_APP_TABLE_LIMIT)
         }
         if (json.update) {
            update = json.update
            !update.site && (update.site = process.env.VUE_APP_SITE)
            !update.zone && (update.zone = state.zone)
         }
         if (json.import) {
            json.import.map(j => {
               !j.site && (j.site = process.env.VUE_APP_SITE)
               !j.zone && (j.zone = state.zone)
            })
         }
         if (json.delete) {
            !json.delete.site && (json.delete.site = process.env.VUE_APP_SITE)
            !json.delete.zone && (json.delete.zone = state.zone)
         }
         if (json.report) {
            report = json.report
            !report.site && (report.site = process.env.VUE_APP_SITE)
            !report.zone && (report.zone = state.zone)
            report.zone === '*' && delete report.zone
         }

         const data = { select, search, project, options, update, import: json.import, delete: json.delete, report } // , upload: json.upload
         // json.headers.command === 'selectActivity' && console.log('mongoose ( data )', jsonify(data))
         const config = { headers: { authorization: `Bearer ${state.token}` } }
         Object.assign(config.headers, json.headers)
         return new Promise((resolve) => {
            axios.post(process.env.VUE_APP_POST, cleanUp(data), config).then(response => resolve(response)).finally(() => { state.process = '' })
         })
      },
      messaging({ state }, json) {
         state.process = 'notifyActivity' // notifyEmail, notifyTelegram, notifyWhatsapp
         !json.zone && (json.zone = state.zone)
         var data = { notify: json }
         var config = { headers: { authorization: `Bearer ${state.token}`, command: 'notifyActivity' } }
         return new Promise((resolve) => {
            axios.post(process.env.VUE_APP_POST, cleanUp(data), config).then(response => resolve(response)).finally(() => { state.process = '' })
         })
      },
   },
   modules: {
   }
})
